.show-pdf-button{
    background: grey;
    outline: none;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.show-pdf{
    padding-top: 1rem;
    padding-left: 8px;
    padding-bottom: 2rem;
}
.css-l3rx45 {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0px 70px;
}
.rpv-open__input-wrapper, .rpv-core__popver-body-toolbar-more-actions{
    display: none !important;
}
#root > section > div > div:nth-child(2) > div.css-16vu25q > div > div > div > div.rpv-default-layout__container > div > div.rpv-default-layout__body > div.rpv-default-layout__toolbar > div > div.rpv-toolbar__right > div.rpv-toolbar__item > div{
    display: none !important;
}