.openPdf{
    position: fixed;
    top: 0;
    left: 0;
    background: #0008;
    width: 100%;
    height: 100vh;
    z-index: 4;
    overflow: scroll;
}

.pdf-container{
    display: flex;
    justify-content: center;
    user-select: none;
    overflow: hidden;
}
.wrap{
    margin: 0 1rem;
}

.controls{
    margin: 5px;
    justify-content: center;

}
.controls button{
    padding: 1rem 2rem;
    background-color: gray;
    color: white;
    border-radius: 5px;
    margin-right: 2rem;
    outline: none;
    border: none;
    box-shadow: 1px 2px 5px black;
}

@media print{
    .pdf-container{
        display: none;
    }
}