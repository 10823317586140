.css-1rwc17d {
    background: linear-gradient(to right, #0074b5 0%, #75daff 100%);
    height: 6px;
}

.css-1qcml3q {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    background-color: #ffffff;
    padding-left: 48px;
    padding-right: 48px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.css-vurnku {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.css-1behg4x {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 1.1rem;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-sskaa1 {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding-bottom: 8px;
    width: 100%;
}

.css-1qpry6n-AccountLayout {
    color: #41bcea;
}

.css-kz6ioj {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: bold;
    padding-right: 8px;
}

.css-qdtep2 {
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    background-color: #140e41;
    color: #ffffff;
    font-family: "Roboto Slab";
    font-weight: 700;
}

.css-1w070cj {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: bold;
}

.css-qdtep2 {
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    background-color: #140e41;
    color: #ffffff;
    font-family: "Roboto Slab";
    font-weight: 700;
}

/*  */
.css-12vj5sp {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    background-color: #f0fafe;
    padding: 64px;
    font-family: Roboto Slab;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-1mb6ai6 {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    max-width: 1100px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-1vj6xes {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    color: #41bcea;
    font-weight: 700;
    font-size: 1.75rem;
}

.css-lu6j54 {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    color: #140e41;
    font-weight: 400;
    font-size: 3.5rem;
}

/* section */
.css-1usr1wh {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-1tkx55p {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 20%;
    min-width: 310px;
}
.css-14mgz8m-ScheduleFilters {
}
.css-c24bqj {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 1.2rem;
    background-color: #140e41;
    color: #ffffff;
    padding: 8px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-1osjthh {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 8px;
    padding-top: 24px;
    font-size: 1.6rem;
    font-family: 'Roboto Slab',serif;
}
.css-h9fdzh {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 8px;
}

.css-14mgz8m-ScheduleFilters ul {
    list-style: none;
    padding-left: 16px;
    margin: 0px;
}
.css-14mgz8m-ScheduleFilters ul li {
    color: #41bcea;
    padding: 4px;
    position: relative;
}
.css-14mgz8m-ScheduleFilters ul li a {
    color: inherit;
    -webkit-text-decoration: none;
    text-decoration: none;
}
.css-14mgz8m-ScheduleFilters span[class*=-count] {
    font-style: italic;
    font-size: 0.9rem;
    bottom: 1px;
    margin-left: 8px;
}

.css-14mgz8m-ScheduleFilters ul li {
    color: #41bcea;
    padding: 4px;
    position: relative;
}
.css-okn6en {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 8px;
    font-size: 1.6rem;
    color: #ffffff;
    font-family: 'Roboto Slab',serif;
}

.css-okga05-YearSelector {
}

.css-sg489d {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: #41bcea;
    font-weight: normal;
}
.css-sg489d {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: #41bcea;
    font-weight: normal;
}

.css-okga05-YearSelector ul {
    padding-left: 0px;
}

.css-okga05-YearSelector ul {
    list-style: none;
    padding-left: 16px;
    margin: 0px;
}

.css-okga05-YearSelector ul li {
    color: #41bcea;
    padding: 4px;
    position: relative;
}

.css-okga05-YearSelector ul li label {
    cursor: pointer;
}

.css-okga05-YearSelector ul li input {
    margin-right: 8px;
}

.css-okga05-YearSelector ul li label>* {
    vertical-align: middle;
    display: inline-block;
}

@media screen and (min-width: 576px){
    .css-ecz0zi {
        padding: 16px;
    }
}

.css-ecz0zi {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    background-color: #ffffff;
    width: 80%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-h9fdzh {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 8px;
}

.css-j0oxed {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-uee49b {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-right: 48px;
    font-size: 0px;
}

.css-2qgr2-SearchLabel {
    position: relative;
}

.css-marnar {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    background-color: #3473b0;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px;
    color: #ffffff;
    font-size: 1.4rem;
    font-family: 'Roboto Slab',serif;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-1yza6mu-SearchLabel {
    height: 39px;
    width: 39px;
    background-color: #3473b0;
    transform-origin: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    display: block;
    right: -19px;
    top: 8px;
}

.css-q3upwa {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding-right: 8px;
    font-size: 0px;
}
.css-14p96h-SearchLabel {
    z-index: 1;
}
.css-yd7lbe {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: bold;
    font-size: 1.2rem;
}
.css-13lplv1-SearchBar {
}

.css-44mn3c {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
}
.css-13lplv1-SearchBar input {
    width: 100%;
    outline: 0;
    padding: 16px;
    font-size: 1.2rem;
    border: 5px solid #f2f2f2;
    color: #140e41;
    font-style: italic;
}

.css-13lplv1-SearchBar button, .css-13lplv1-SearchBar .ais-SearchBox-loadingIndicator {
    display: none;
}

.show-pdf {
    padding-top: 1rem;
    padding-left: 8px;
    margin-bottom: 10px;
}

.show-pdf-button {
    background: grey;
    outline: none;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.css-tzaj1n {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    padding: 8px;
    color: #3473b0;
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-ik0lim {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 8%;
    padding: 8px;
}
.css-ik0lim {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 8%;
    padding: 8px;
}
.css-ik0lim {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 8%;
    padding: 8px;
}
.css-97ef8e {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 8%;
    padding: 8px;
    color: #140e41;
}

.css-vurnku {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.css-1sl4ywr {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    padding: 16px;
    background-color: #f2f2f2;
    font-weight: bold;
    font-size: 1.4rem;
}

.css-8k31p5-Level2Heading {
    border-bottom: 2px solid #f2f2f2;
}

.css-vdinwm {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    padding: 8px;
    font-weight: bold;
    font-size: 1.2rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.css-h9fdzh {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 8px;
}

.css-h9fdzh {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 8px;
}

.css-1xy42d1-ScheduleItem {
    border-bottom: 2px solid #f2f2f2;
}

.css-jusk0a {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    font-size: 1.2rem;
    padding: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-1konojr {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 0px;
}

.css-rerot2-ImgIcon {
    background-image: url("https://nsrm.webchoicetest.com/wp-content/uploads/2022/07/Housing-Maintenance-image.jpg");
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-background-position: center;
    background-position: center;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    display: inline-block;
    cursor: default;
    font-size: 0.8rem;
}

.css-bjf42r {
    font-weight: bold;
    color: #3473b0;
    padding: 8px;
    text-align: center;
    width: 8%;
    min-width: 8%;
    -webkit-filter: blur(0px);
    filter: blur(0px);
}

.css-1pkgl05 {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 8px;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
}

.css-1mydbss {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 0px;
}
.css-bjf42r {
    font-weight: bold;
    color: #3473b0;
    padding: 8px;
    text-align: center;
    width: 8%;
    min-width: 8%;
    -webkit-filter: blur(0px);
    filter: blur(0px);
}
.css-vurnku {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}
.css-1qqh5hw {
    font-weight: bold;
    color: #3473b0;
    padding: 8px;
    text-align: center;
    width: 8%;
    min-width: 8%;
    -webkit-filter: blur(5px);
    filter: blur(5px);
}
.css-vurnku {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}
.css-qcl07c {
    font-weight: bold;
    padding: 8px;
    text-align: center;
    width: 8%;
    min-width: 8%;
    -webkit-filter: blur(5px);
    filter: blur(5px);
}
.css-rgbw1c {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: brand;
}

.css-8k31p5-Level2Heading {
    border-bottom: 2px solid #f2f2f2;
}

.css-vdinwm {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    padding: 8px;
    font-weight: bold;
    font-size: 1.2rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-h9fdzh {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 8px;
}

.css-h9fdzh {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 8px;
}
.css-1mydbss {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 0px;
}
.css-h9fdzh {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 8px;
}