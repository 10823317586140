@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');

html,
body {
	padding: 0;
	margin: 0;
	font-family: Arial, Helvetica, sans-serif;
	height: 100%;
	font-size: 14px;
	color: #140e41;
	background-color: #f0fafe;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Roboto Slab', serif;
	font-weight: 700;
}

#root {
	height: 100%;
}

// .css-1uyeslq {
//   filter: blur(5px);
// }